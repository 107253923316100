<template>
  <b-card
    :title="$t('UserV.Change Password.title')"
  >
    <validation-observer
      ref="changePasswordForm"
      #default="{invalid}"
    >
      <!-- form -->
      <b-form @submit.prevent="submitForm">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label-for="account-old-password"
              :label="$t('UserV.Change Password.form.labels.oldPassword')"
            >
              <validation-provider
                #default="{ errors }"
                name="Old Password"
                vid="oldPassword"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    :state="errors.length > 0 ? false:null"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    :placeholder="$t('UserV.Change Password.form.placeholders.oldPassword')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              :label="$t('UserV.Change Password.form.labels.newPassword')"
            >
              <validation-provider
                #default="{ errors }"
                name="New Password"
                vid="newPassword"
                rules="required|is_not:@oldPassword|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :state="errors.length > 0 ? false:null"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    :placeholder="$t('UserV.Change Password.form.placeholders.newPassword')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              :label="$t('UserV.Change Password.form.labels.retypePassword')"
            >
              <validation-provider
                #default="{ errors }"
                name="Retype Password"
                vid="retypePassword"
                rules="required|confirmed:newPassword"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :state="errors.length > 0 ? false:null"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    :placeholder="$t('UserV.Change Password.form.placeholders.retypePassword')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="invalid"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
            >
              {{ $t('UserV.Change Password.form.buttonTexts.save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              {{ $t('UserV.Change Password.form.buttonTexts.reset') }}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, isNot, confirmed, password,
} from '@validations'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: process.env.VUE_APP_PRODUCTION ? '' : 'S1mpl3P455w0rd!',
      newPasswordValue: process.env.VUE_APP_PRODUCTION ? '' : 'S1mpl3P455w0rd!',
      RetypePassword: process.env.VUE_APP_PRODUCTION ? '' : 'S1mpl3P455w0rd!',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required,
      isNot,
      confirmed,
      password,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    clearForm() {
      this.passwordValueOld = ''
      this.newPasswordValue = ''
      this.RetypePassword = ''
    },
    failedToUpdatePassword(error) {
      this.$swal({
        title: this.$i18n.t('SwAl.titles.error'),
        html: this.$i18n.t('SwAl.htmls.couldntUpdatePassword', { error }),
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.clearForm()
      })
    },
    saveNewPassword() {
      this.$http.post('/jwt2/password', {
        old_password: this.passwordValueOld,
        password: this.newPasswordValue,
      }).then(response => {
        if (response.status === 200) {
          this.$swal({
            title: this.$i18n.t('SwAl.titles.passwordUpdated'),
            html: this.$i18n.t('SwAl.htmls.passwordUpdated'),
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(() => {
            this.clearForm()
          })
        } else {
          this.failedToUpdatePassword('')
        }
      }).catch(error => {
        this.failedToUpdatePassword(error)
      })
    },
    submitForm() {
      this.$refs.changePasswordForm.validate().then(success => {
        if (success) {
          this.saveNewPassword()
        }
      })
    },
  },
}
</script>
